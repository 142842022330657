<template>
<div id="apply">
   <div class="apply-wrap">
      <h3>申请入驻</h3>
      <div class="applyFrom">
          <el-form :inline="true" :model="applyModel" :rules="rules" ref="ruleForm" label-width="120px">
            <el-form-item label="服务行业" prop="serviceTrades">
              <el-cascader @change="selectTrade"  v-model="applyModel.serviceTrades" :options="tradeList" placeholder="请选择服务行业编码" :props="{
                    value:'value',
                    label:'label',
                    children:'children',
                     multiple: true,
                     emitPath: false
                   }" :show-all-levels="false">
              </el-cascader>
            </el-form-item>
            <el-form-item label="服务商名称" prop="serviceName">
              <el-input  placeholder="请输入服务商名称" clearable v-model="applyModel.serviceName"></el-input>
            </el-form-item>
            <el-form-item label="申请人姓名" prop="userName">
              <el-input  placeholder="请输入申请人姓名" clearable v-model="applyModel.userName"></el-input>
            </el-form-item>
            <el-form-item label="联系手机号" prop="phone">
              <el-input  placeholder="请输入联系手机号" clearable v-model="applyModel.phone"></el-input>
            </el-form-item>
            <el-form-item label="所在地区"  prop="citys">
                <el-cascader clearable class="citys"  v-model="applyModel.citys" :show-all-levels="true" @change="selectCitys" :options="areaList" placeholder="请选择所在区域">
                 </el-cascader>
            </el-form-item>
            <div class="materialBox">
               <h4>
                 <span>*</span><label>营业执照</label>
               </h4>
               <div class="materialUpload">
                  <el-upload
                    :on-success="licenseSuccess"
                    :action="baseUrl"
                    :limit="1"
                   >
                    <el-button size="small" type="primary">点击上传</el-button>
                  </el-upload>
               </div>
             </div>
             <div class="materialBox">
               <h4>
                  <span>*</span><label>身份证正面</label>
               </h4>
               <div class="materialUpload" style="margin-left:17px">
                  <el-upload
                    :on-success="idCardZSuccess"
                    :on-remove="idCardZRemove"
                    :action="baseUrl"
                   >
                    <el-button size="small" type="primary">点击上传</el-button>
                  </el-upload>
               </div>
             </div>
             <div class="materialBox">
               <h4>
                  <span>*</span><label>身份证反面</label>
               </h4>
               <div class="materialUpload" style="margin-left:17px">
                  <el-upload
                    :on-success="idCardFSuccess"
                    :on-remove="idCardFRemove"
                    :action="baseUrl"
                   >
                    <el-button size="small" type="primary">点击上传</el-button>
                  </el-upload>
               </div>
             </div>
             <div class="materialBox">
               <h4>
                 <span></span><label>申请资料</label>
               </h4>
               <div class="materialUpload" style="margin-left:17px">
                  <el-upload
                    :on-success="materialSuccess"
                    :on-remove="materialRemove"
                    :action="baseUrl"
                   >
                    <el-button size="small" type="primary">点击上传</el-button>
                    <div slot="tip" class="el-upload__tip">{{dataDescribe.join(',')}}</div>
                  </el-upload>
               </div>
             </div>
             <el-form-item label="详细地址" prop="address">
                <el-input @focus="openMap" class="address" v-model="applyModel.address" placeholder="请选择详细地址"></el-input>
             </el-form-item>
             <el-form-item label="申请说明" prop="applicationReason">
               <el-input type="textarea" v-model="applyModel.applicationReason" placeholder="请输入申请说明"></el-input>
             </el-form-item>
             <div class="saveBtn" @click="applicationForm">申请入驻</div>
          </el-form>
      </div>
       <!-- 地图 -->
      <div class="mapBox">
        <el-dialog title="选择详细地址" :visible.sync="dialogMap" width="700px">
          <div class="workMap">
            <el-amap-search-box  class="search-box"  :search-option="searchOption"  :on-search-result="onSearchResult">
            </el-amap-search-box>

            <el-amap ref="map"  vid="amapDemo" :events="events" zoom="18" :center="center"  class="amap-demo">
              <el-amap-marker key="100" :position="center"></el-amap-marker>
            </el-amap>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogMap = false">取 消</el-button>
            <el-button type="primary" @click="setMap" >确 定</el-button>
          </span>
        </el-dialog>
      </div>
   </div>
</div>
</template>

<script>
import {getServiceTrade,getWorkArea,applicationForm } from "@/api/index.js";
export default {
  name: "apply",
  data() {
    let _this = this;
    return {
     applyModel:{
       serviceTrades:[],
       userName:'',
       phone:'',
       idCard:'',
       serviceName:'',
       region:'',
       citys:[],
       applicationMaterialsUrl:'',
       address:'',
       latitude:'',
       longitude:'',
       applicationReason:'',
       businessLicenseUrl:'',
       idCardZ:''
     },
     materialsUrlList:[],
     tradeList:[],
     areaList: [],
     baseUrl:'',
     dataDescribe:[],
     rules:{
        serviceTrades: [
          { required: true, message: '请选择服务行业', trigger: 'change' }
        ],
        userName: [
          { required: true, message: '请输入申请人姓名', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入联系手机号', trigger: 'blur' }
        ],
        idCard: [
          { required: true, message: '请输入身份证号', trigger: 'blur' }
        ],
        serviceName: [
          { required: true, message: '请输入服务商名称', trigger: 'blur' }
        ],
        region: [
          { required: true, message: '请选择服务行业编码', trigger: 'change' }
        ],
        address: [
          { required: true, message: '请选择详细地址', trigger: 'change' }
        ],
        citys: [
          { required: true, message: '请选择所在地区', trigger: 'change' }
        ],
     },
     dialogMap:false,
     center: [93.51452,42.82699],
     events: {
        click(e) {
           _this.center = [e.lnglat.lng, e.lnglat.lat];
          _this.getFormattedAddress();

        }
     },
     searchOption: {
      // 限制搜索城市的范围
      citylimit: false,
     },
    }
  },
  mounted() {
    this.getServiceTrade()
    this.getWorkArea()
    var userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.applyModel.userName = userInfo.userName
    this.applyModel.phone = userInfo.phone
    this.applyModel.idCard = userInfo.idCard
    this.baseUrl = process.env.VUE_APP_BASE_API + '/webApi/common/upload'
  },
  methods: {
    selectCitys(val){
      this.applyModel.region = val.join(',')
    },
    applicationForm(){
      this.applyModel.applicationMaterialsUrl = this.materialsUrlList.join(',')
      applicationForm(this.applyModel).then(res=>{
          if(res.code==200){
             this.$message({
                message: '申请入驻成功',
                type: 'success'
            })
             this.$router.push({
                path: '/market'
              })
          }else{
            this.$message({
                message: res.msg,
                type: 'warning'
              })
          }
      })
    },
    openMap(){
      this.dialogMap = true
    },
    setMap(){
      this.applyModel.longitude = this.center[0]
      this.applyModel.latitude = this.center[1]
      this.dialogMap = false
    },
    getFormattedAddress() {
      window.AMap.plugin('AMap.Geocoder', () => {
        let GeocoderOptions = {
            city: '全国'
        };
        let geocoder = new window.AMap.Geocoder(GeocoderOptions);
        geocoder.getAddress(this.center, (status, result) => {
            console.log('通过经纬度拿到的地址', result);
            if (status === 'complete' && result.info === 'OK') {
              this.applyModel.address = result.regeocode.formattedAddress;
            }
        });
      });
    },
    onSearchResult(pois) {
      if (pois && pois.length > 0) {
        //如果长度为1则无需转化
        let poi = pois[0];
        let lng = poi["lng"];
        let lat = poi["lat"];
        this.center = [lng, lat]
      }
    },
    materialSuccess(file){
      this.materialsUrlList.push(file.url)
    },
    licenseSuccess(file){
      this.applyModel.businessLicenseUrl = file.url
    },
    idCardZSuccess(file){
      this.applyModel.idCardZ = file.url
    },
    idCardZRemove(){
      this.applyModel.idCardZ = ''
    },
    idCardFSuccess(file){
       this.applyModel.idCardF = file.url
    },
    idCardFRemove(){
      this.applyModel.idCardF = ''
    },
    materialRemove(file,fileList){
      this.materialsUrlList = []
       fileList.forEach(item => {
           this.materialsUrlList.push(item.response.url)
       });
    },
    getWorkArea() {
      getWorkArea().then(res => {
        this.areaList = res.rows
      })
    },
    getServiceTrade(){
      getServiceTrade().then(res=>{
        this.tradeList = res.rows
      })
    },
    selectTrade(value){
      console.log(value)
      this.dataDescribe = []
      value.forEach(item=>{
         this.tradeList.forEach(s=>{
          s.children.forEach(g=>{
             if(item==g.value){
              if(g.dataDescribe){
                this.dataDescribe.push(g.dataDescribe)
              }
             }
          })
         })
      })
    }
  }
};
</script>

<style lang="scss" scoped>
#apply {
  width: 100%;
  min-height: 200px;
  background: linear-gradient(0deg, #F5F5F5 1%, #D6E9FF 100%);
  overflow: hidden;
  padding-top: 24px;
   padding-bottom: 20px;
}

.apply-wrap {
    width: 900px;
    margin: 0 auto;
    min-height: 430px;
    background: #FFFFFF;
    padding: 42px 100px 41px 90px;
    border-radius: 1px 10px 10px 10px;
    h3{
          font-size: 24px;
      font-weight: bold;
      color: #222222;
      text-align: center;
    }
}
.applyFrom{
  margin-top: 30px;
}
.materialBox{
  margin-bottom: 25px;
  overflow: hidden;
  h4{
    font-size: 16px;
    color: #000000;
    margin-left: 31px;
    float: left;
    font-weight: 500;
    width: 110px;
    text-align: right;
    span{
      color: #F56C6C;
      display: inline-block;
      margin-right: 4px;
    }
  }
  .materialUpload{
    float: left;
    width: 500px;
    margin-left: 17px;
  }
}
.workMap{
  overflow: hidden;
  .amap-demo{
    height:280px;
  }
  .el-vue-search-box-container {
   width: 644px;
   margin-left: 0;
   margin-bottom: 10px;
   border: 1px solid #eee;
   padding-right: 10px;
  }
}
.saveBtn {
  text-align: center;
  width: 265px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  font-size: 16px;
  color: #FFFFFF;
  background: #0077FF;
  border-radius: 5px;
  margin-left:300px;
  cursor: pointer;
}
</style>
<style lang="scss">
.applyFrom {
  .el-form-item {
    margin-bottom: 40px;
  }

  .el-form-item__label {
    font-size: 16px;
    color: #000000;
  }

  .el-input__inner {
    width: 320px;
    height: 40px;
    background: #F5F5F5;
    border-radius: 5px;
    border: none;
    font-size: 16px;
    color: #999999;
  }
  .el-cascader{
    width: 770px;
     .el-input__inner {
    width: 770px;
    }
  }
  .citys{
     width: 320px;
     .el-input__inner {
    width: 320px;
    }
  }
  .address{
    .el-input__inner {
      width: 750px;
    }
  }
  .el-textarea__inner {
    width: 750px;
    min-height: 140px !important;
    background: #F5F5F5;
    border-radius: 5px;
    border: none;
    font-size: 16px;
    color: #999999;
  }
}
.mapBox{
  .el-dialog{
    height: 480px;
  }
  .el-dialog__body{
    padding:10px 20px;
  }
}
.materialUpload{
  .el-button--primary{
    background: #0077FF;
    border: none;
    width: 100px;
  }
}
</style>


